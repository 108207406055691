import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

// run plugin with your configuration
/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({

    categories: {
        necessary: {
            enabled: true,
            readOnly: true
        },
        targeting: {
            enabled: true,
            readOnly: false
        },
        analytics: {
            enabled: true,
            readOnly: false
        }
    },

    language: {
        default: 'cs',
        translations: {
            cs: {
                consentModal: {
                    title: 'Používáme cookie',
                    description: 'Dobrý den, tento web používá nezbytné soubory cookie k zajištění správného fungování a sledovací soubory cookie, abychom pochopili, jak s nimi komunikujete. Ten bude nastaven až po souhlasu. <button type="button" data-cc="c-settings" class="cc-link">Podrobné nastavení</button>',
                    acceptAllBtn: 'Příjmout vše',
                    acceptNecessaryBtn: 'Odmítnout vše',
                    showPreferencesBtn: 'Spravovat individuální preference'
                },
                preferencesModal: {
                    title: 'Nastavení cookies',
                    acceptAllBtn: 'Příjmout vše',
                    acceptNecessaryBtn: 'Odmítnout vše',
                    savePreferencesBtn: 'Příjmout aktuální výběr',
                    closeIconLabel: 'Zavřít',
                    sections: [
                        {
                            title: 'Použití cookies 📢',
                            description: 'Soubory cookie používám k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění <a href="/zasady-cookies" class="cc-link">zde</a>.'
                        },
                        {
                            title: 'Nezbytné nutné cookies',
                            description: 'Technické soubory cookies jsou takové, které webová stránka využívá pro přenos dat a elektronickou komunikaci v síti. A to pouze v tom rozsahu, který je nezbytný pro fungování těchto webových stránek. Nejsou používané pro žádné další účely a k jejich použití není vyžadován váš souhlas.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Výkonnostní a analytické soubory cookie',
                            description: 'Analytické soubory cookies Google Analytics jsou anonymizované a slouží výhradně ke statistickým účelům pro správce webu. Ti díky nim mohou sledovat, jaká část stránek uživatele nejvíce zajímá, které texty jsou nejpopulárnější a které funkce webu je vhodné vylepšit. Díky těmto souborům cookies je možné stránky Bricksáček dělat uživatelsky pohodlnější.',
                            linkedCategory: 'analytics'
                        },
                        {
                            title: 'Marketingové a preferenční cookies',
                            description: 'Tento druh souborů cookies schraňuje data za účelem přizpůsobení reklamy specifickým zájmům uživatele. Tento typ souborů mohou používat služby třetích stran, které web Bricksáček využívá. Například sociální sítě díky nim zobrazují personalizovanou reklamu (např. Facebook, Twitter, Instagram).',
                            linkedCategory: 'targeting'
                        },
                        {
                            title: 'Více informací',
                            description: 'Máte-li jakékoli dotazy týkající se našich zásad týkajících se souborů cookie a vašich voleb, prosím <a class="cc-link" href="/kontakty">kontaktujte nás</a>.'
                        }
                    ]
                }
            }
        }
    }
});
